import { Button, DatePicker, Form, Input, Modal, Select } from "antd"
import ActionBtn from "../../Buttons/actionBtn";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Option } from "antd/es/mentions";
import { useDispatch, useSelector } from "react-redux";
import { loadAllSale } from "../../../redux/rtk/features/sale/saleSlice";
import { updateItinerary } from "../../../redux/rtk/features/itinerary/itinerarySlice";
import { StatusEnum } from "../../../enums";
import { toast } from "react-toastify";

const Sanction = ({ open, setOpen, details, refreshList }) => {
    const userStore = useSelector((state)=>state.user);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const {
        list,
    } = useSelector((state) => state.sales);
    console.log(list, "sale")
    const [loading, setLoading] = useState(false);
    const sales = useMemo(() => {
        return list?.allSaleInvoice ?? []
    }, [list])
    const validateQuantity = (_, value) => {
        console.log(value, "VALUE", details)
        if (details?.quantity < value) {
            return Promise.reject(`Quantity Should be less than or equal to ${details.quantity}`);
        }
        if (value == 0) {
            return Promise.reject(`Quantity Should be 0`);
        }
        return Promise.resolve();
    };
    const formFields = useMemo(() => {
        console.log(sales.length, "Length...")
        const feilds = [
            {
                label: "Cost",
                name: "costPerItinerary",
                required: true,
                message: "Please Enter Cost Per Itenary!",
                placeholder: "Enter Cost Per Itenary",
            },
            {
                label: "Quantity",
                name: "sanctionedQuantity",
                required: true,
                message: "Please Enter Sanction Quantity!",
                placeholder: "Sanction Quantity",
                validation: validateQuantity
            },
        ];

        if (details?.type == "PRODUCT") {
            const salIdFeild = {
                label: "Sale Id",
                name: "saleId",
                required: true,
                showSearch: true,
                loading: false,
                message: "Please Select Sale Id",
                placeholder: "Select Itenary Sale Id",
                type: "select",
                onChange: (value) => {
                    const data = JSON.parse(value);
                    form.setFieldValue('saleId', data.id)
                },
                options: sales.map((item) => ({ name: `#SaleId:${item.id}`, value: JSON.stringify(item) }))
            };
            feilds.splice(0, 1, salIdFeild);
        }

        return feilds;
    }, [sales, details])

    useEffect(() => {
        const currentDate = new Date();
        let startdate = new Date().setMonth(currentDate.getMonth() - 1);
        startdate = new Date(startdate);
        const enddate = new Date().toDateString();
        dispatch(
            loadAllSale({
                page: 1,
                count: 10,
                startdate: startdate,
                enddate: enddate,
                query: "all",
            })
        );
    }, [])


    const onFinish = async (values) => {
        try {
            console.log(values, "values..");
            const userId = await localStorage.getItem('id');
            let body = {
                id: details.id,
                sanctionedQuantity: parseInt(values.sanctionedQuantity),
                totalAmount: parseInt(values.costPerItinerary) * parseInt(values.sanctionedQuantity),
                totalSanctionedAmount: parseInt(values.costPerItinerary) * parseInt(values.sanctionedQuantity),
                status: StatusEnum.Sanctioned,
                approvedBy:parseInt(userId) 
            };
            
            if (details.type == "PRODUCT") {
                body = {
                    ...body,
                    saleId: JSON.parse(values.saleId).id ?? "",
                    totalAmount: parseInt(details.costPerItinerary) * parseInt(values.sanctionedQuantity),
                    totalSanctionedAmount: parseInt(details.costPerItinerary) * parseInt(values.sanctionedQuantity),
                }
            } else {
                body = {
                    ...body,
                    costPerItinerary: parseInt(values.costPerItinerary),

                }
            }

            console.log(body, 'body...')
            setLoading(true);
            const resp = await dispatch(updateItinerary(body));
            if (resp.payload.message === "success") {
                setLoading(false);
                onCloseModal();
                refreshList();
                toast.success('Itenary Updated Successfully')
            }
            else {
                setLoading(false)
                toast.error(resp.payload.message ?? 'Something went wrong');
            }
        } catch (error) {
            setLoading(false);
            console.log(error, 'error')
            toast.error(error.message ?? 'Something went wrong');

        }
    };
    const onCloseModal = useCallback(() => {
        form.resetFields()
        setOpen()
    }, [])
    const onFinishFailed = (errorInfo) => {
    };

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"

        >
            <Modal
                title={<p>Sanction</p>}

                style={{
                    top: 20,
                }}
                footer={
                    <div className=" flex-row items-center justify-end w-[100%]">
                        <ActionBtn title={'Cancel'} onClick={onCloseModal} textClassName={`text-teal-700`} className={`bg-white text-teal-700 border border-teal-600  mx-4 `} />
                        <ActionBtn title={'Submit'}
                            onClick={() => { form.submit() }}
                            isLoading={loading} className={`bg-teal-700  border-teal-700`} />
                    </div>
                }
                // loading={loading}
                open={open}
                onCancel={setOpen}
            >
                <div className="items-center d-flex  p-2 ">
                    {formFields.map((item) => {
                        if (item.type === "select") {
                            return (
                                <Form.Item
                                    key={item.name}
                                    label={item.label}
                                    name={item.name}
                                    className="pt-2"
                                    rules={[{ required: item.required, message: item.message, validator: item.validation },
                                    { validator: item.validation }
                                    ]}
                                >
                                    <Select

                                        placeholder={item.placeholder}
                                        showSearch={item.showSearch}
                                        loading={item.loading}
                                        onChange={(value) => {
                                            item?.onChange(value);
                                            form.setFieldValue(item.name, value)
                                        }}
                                    >
                                        {item.options &&
                                            item.options.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.name}
                                                </Option>
                                            ))}
                                        {item.renderOption}
                                    </Select>
                                </Form.Item>
                            );
                        } else if (item.type === "date") {
                            return (
                                <Form.Item
                                    key={item.name}
                                    className="pt-2"
                                    label={item.label}
                                    name={item.name}
                                    rules={[{ required: item.required, message: item.message, }, { validator: item.validation }]}
                                >
                                    <DatePicker
                                        placeholder={item.placeholder}
                                        style={{ width: "100%", marginTop: 10 }}
                                    />
                                </Form.Item>
                            );
                        } else {
                            return (
                                <Form.Item
                                    key={item.name}
                                    label={item.label}
                                    name={item.name}
                                    className="pt-2"
                                    rules={[
                                        { required: item.required, message: item.message, },
                                        { validator: item.validation }
                                    ]}
                                >
                                    <Input
                                        // style={{ flex:1, display:'flex', backgroundColor:'yellow',width:'100%' }}
                                        className="w-[100%]"

                                        placeholder={item.placeholder}
                                        type={item.type ? item.type : "text"}
                                    />
                                </Form.Item>
                            );
                        }
                    })}
                </div>

            </Modal>
        </Form>
    )
}
export default Sanction;