import { Button, Form, Input, Select, DatePicker, message } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./projectDetails.css";
import moment from "moment";
import {
  updateItinerary,
  fetchSingleItinerary,
} from "../../redux/rtk/features/itinerary/itinerarySlice";
import axios from "axios";

const { RangePicker } = DatePicker;

const UpdateItnearyDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { singleItinerary } = useSelector((state) => state.itinerary);
  const [customers, setCustomers] = useState([]);
  console.log(customers);
  
  const [loadingCustomers, setLoadingCustomers] = useState(true);

  useEffect(() => {
    dispatch(fetchSingleItinerary(id));
  }, [dispatch, id]);

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await axios.get(`/sale-invoice/customer/${id}`);
        setCustomers(response?.data);
      } catch (error) {
        message.error("Failed to fetch sales");
        console.error(error);
      } finally {
        setLoadingCustomers(false);
      }
    };

    fetchSales();
  }, []);

  const onFinish = async (values) => {
    try {
      const payload = {
        ...values,
        estimationAmount: parseInt(values.estimationAmount, 10),
        duration: parseInt(values.duration, 10),
      };
      await dispatch(updateItinerary({ id, ...payload }));
      message.success("Itneary Updated Successfully");
      navigate(`/admin/projectDetails/${id}`);
    } catch (error) {
      message.error("Failed To Update Itneary");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const formatDate = (date) => {
    return date ? moment(date) : null;
  };

  return (
    <Fragment>
      <div style={{ alignContent: "center", textAlign: "-webkit-center" }}>
        <Form
          style={{
            maxWidth: "700px",
            border: "1px solid #ccc",
            padding: "40px",
            borderRadius: "10px",
          }}
          name="updateWarehouseForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            ...singleItinerary,
            startDate: formatDate(singleItinerary.startDate),
            endDate: formatDate(singleItinerary.endDate),
          }}
        >
          <h1 className="font-bold text-2xl mb-5">Update Itneary</h1>

          <Form.Item
            label="Sale Id"
            name="saleId"
            rules={[{ required: true, message: "Please select sale Id!" }]}
          >
            <Select
              style={{ textAlign: "left" }}
              className="mb-2"
              placeholder="Select Sale Id"
              loading={loadingCustomers}
            >
              {customers.getAllCustomer &&
                customers.getAllCustomer.map((customer) => (
                  <Select.Option
                    style={{ textAlign: "left" }}
                    key={customer.id}
                    value={customer.id}
                  >
                    {customer.id}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Sanctioned Quantity"
            name="sanctionedQuantity"
            rules={[
              { required: false, message: "Please input the sanctioned Quantity!" },
            ]}
          >
            <Input className="mb-2" />
          </Form.Item>

          <Form.Item
            label="Sanctioned Amount"
            name="totalSanctionedAmount"
            rules={[{ required: false, message: "Please input the total Sanctioned Amount!" }]}
          >
            <Input type="number" className="mb-2" />
          </Form.Item>

          <Form.Item>
            <Button className="mt-5" type="primary" htmlType="submit">
              Update Itneary
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Fragment>
  );
};

export default UpdateItnearyDetails;
