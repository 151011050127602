import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Typography, Button, message, Select } from "antd";
import { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import TableComponent from "../CommonUi/TableComponent";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import {
  deleteProject,
  fetchSingleProject,
  updateProject,
} from "../../redux/rtk/features/projects/projectsSlice";
import ViewBtn from "../Buttons/ViewBtn";
import { fetchItinerary } from "../../redux/rtk/features/itinerary/itinerarySlice";
import Loader from "../loader/loader";
import { useState } from "react";
import ProjectRepo from "../Invoice/ProjectRepo";
import CreateDrawer from "../CommonUi/CreateDrawer";
import AddItenary from "./addItenary";
import IternaryRepo from "../Invoice/IternaryRepo";
import ActionBtn from "../Buttons/actionBtn";
import ReturnRequest from "./returnRequest";
import { StatusEnum } from "../../enums";
import { toast } from "react-toastify";

const ProjectDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [itenaryType, setItenaryType] = useState("");
  const [allItenaries, setAllItenaries] = useState([]);
  const [openReturnRequest, setOpenReturnRequest] = useState(false);
  const [selectedItenary, setSelectedItenary] = useState(undefined);
  const { singleProject } = useSelector((state) => state.projects);
  const { list, ilist } = useSelector((state) => state.itinerary);
  const totali = list.totalItineraries;

  useEffect(() => {
    const fetchData = async () => {

      const queryType = searchValue ? "search" : "";
      await dispatch(
        fetchItinerary({
          project: id,
          query: queryType,
          key: searchValue,
          status: statusFilter,
          type: itenaryType,
          page: 1,
        })
      );
    };

    fetchData();
  }, [dispatch, searchValue, statusFilter, id, itenaryType]);

  const getExportData = useCallback(async () => {

    const res = await dispatch(fetchItinerary({
      project: id,
      query: "all",
      page: 1,
    })).unwrap();
    if (Array.isArray(res)) {
      setAllItenaries(res);
    }
  }, [id])

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      id: 3,
      title: "Created By",
      dataIndex: ["createdByUser", "username"],
      key: "createdByUser.username",
      width: "15%",
    },
    {
      id: 4,
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
    },
    {
      id: 5,
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "10%",
    },
    {
      id: 5,
      title: "Sanction Quantity",
      dataIndex: "sanctionedQuantity",
      key: "sanctionedQuantity",
      width: "10%",
    },
    {
      id: 5,
      title: "Approved By",
      dataIndex: ["approvedByUser","username"],
      key: "approvedByUser.username",
      width: "10%",
    },
    {
      id: 6,
      title: "Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: "10%",
    },
    {
      id: 7,
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: "10%",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
        return formattedDate;
      },
    },
    
    {
      id: 8,
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (status) => {
        switch (status) {
          case "SANCTIONED":
            return "Sanctioned";
          case "REQUESTED":
            return "Requested";
          case "REQUEST_TO_RETURN":
            return "Request To Return";
          case "RETURNED":
            return "Returned";
          default:
            return status;
        }
      },
    },
    {
      id: 9,
      title: "Action",
      key: "action",
      render: (item) => (
        <div className="flex">
          <ViewBtn path={`/admin/projectDetails/itnearyDetails/${item.id}`} />
          {
            (item.type == "PRODUCT" && item.status == StatusEnum.Sanctioned) &&
            <ActionBtn title={'Return Request'} onClick={() => { setSelectedItenary(item); toggleReturnRequestModal() }} />
          }
        </div>
      ),
      width: "25%",
    },
  ];

  const markAsComplete = useCallback(async (itenary) => {
    setLoading(true);
    try {

      const payload = {
        status: "COMPLETED",
      };
      await dispatch(updateProject({ id, ...payload }));
      fetchData();
      toast.success("Project Updated Successfully");

    } catch (error) {
      toast.error("Failed To Update Project");
    }
    setLoading(false);
  }, [id])

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(fetchSingleProject(id));
    } finally {
      setLoading(false);
    }
  }, [id]);
  useEffect(() => {
    fetchData();
  }, [dispatch, id]);


  const toggleReturnRequestModal = useCallback(() => {
    setOpenReturnRequest((state) => !state);
  }, [])

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  if (loading) {
    return <Loader />;
  }

  // console.log(list, "List...");
  return (
    <div>
      <ReturnRequest open={openReturnRequest} setOpen={toggleReturnRequestModal} details={selectedItenary} refreshList={fetchData} />
      <div className="mr-top">
        {singleProject ? (
          <Fragment key={singleProject?.id}>
            <Card bordered={false} className="card-custom">
              <div className="card-header flex justify-between m-3">
                <div className="flex">
                  <SolutionOutlined className="text-xl" />
                  <span className="mr-left mt-1 font-bold text-lg">
                    ID : {singleProject?.id} | {singleProject?.projectName}
                  </span>
                </div>
                <div className="flex items-center gap-3">
                  {
                    singleProject.status == "IN_PROGRESS" &&
                    <ActionBtn title={'Mark As Completed'} className={`bg-green-400`} onClick={markAsComplete} />
                  }
                  <Link
                    className="m-2 inline-block bg-blue-500 p-2 text-white rounded-md"
                    to={`/admin/projectDetails/${singleProject?.id}/update`}
                    state={{ data: {} }}
                  >
                    <EditOutlined
                      style={{
                        color: "white",
                        width: "unset !important",
                        padding: "0 5px 0 5px",
                      }}
                    />
                  </Link>

                </div>
              </div>
              <Row className="flex   ">
                <Col xs={24} xl={8} className="">
                  <div className="card-body  grid">
                    <h5 className="text-xl font-bold mb-2">
                      Project Information
                    </h5>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Project Name :
                      </Typography.Text>{" "}
                      <span className="text">{singleProject?.projectName}</span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Description :
                      </Typography.Text>{" "}
                      <span className="text">{singleProject?.description}</span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Customer :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleProject?.customer?.name}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Start Date :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleProject?.createdAt
                          ? new Date(
                            singleProject.createdAt
                          ).toLocaleDateString("en-GB")
                          : "-"}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        End Date :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleProject?.endDate
                          ? new Date(singleProject.endDate).toLocaleDateString(
                            "en-GB"
                          )
                          : "-"}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Duration :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleProject?.duration + " Days"}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Est. Amount :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleProject?.estimationAmount}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Actual Amount :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleProject?.actualAmount}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Status :
                      </Typography.Text>{" "}
                      <span className="text">
                        {(() => {
                          switch (singleProject?.status) {
                            case "NOT_STARTED":
                              return "Not Started";
                            case "IN_PROGRESS":
                              return "In Progress";
                            case "COMPLETED":
                              return "Completed";
                            default:
                              return singleProject?.status;
                          }
                        })()}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Created By :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleProject?.user?.username}
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Card>
            <div className="lg:flex items-center justify-between py-3">
              <h1 className="text-lg font-bold">Iteneraries List</h1>
              <div className="flex gap-0 md:gap-5 items-center justify-between md:justify-start">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={handleSearch}
                    className="border border-gray-300 rounded-md pl-10 pr-4 py-1 w-48"
                  />
                  <Select
                    className="salelist-saleperson-input ml-2"
                    placeholder="Type"
                    style={{ width: 200 }}
                    allowClear
                    value={itenaryType}
                    onChange={(value) => setItenaryType(value)}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="MANPOWER">Manpower</Select.Option>
                    <Select.Option value="PRODUCT">Product</Select.Option>
                  </Select>

                  <Select
                    className="salelist-saleperson-input ml-2"
                    placeholder="Status"
                    style={{ width: 200 }}
                    allowClear
                    value={statusFilter}
                    onChange={(value) => setStatusFilter(value)}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="REQUESTED">Requested</Select.Option>
                    <Select.Option value="SANCTIONED">Sanctioned</Select.Option>
                    <Select.Option value="REQUEST_TO_RETURN">Retrurn Request</Select.Option>
                    <Select.Option value="RETURNED">Returned</Select.Option>
                  </Select>
                  <SearchOutlined className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                </div>
                <IternaryRepo data={allItenaries} fetchData={getExportData} />
                {
                  (singleProject.status != "COMPLETED") &&
                  <CreateDrawer permission={"create-itinerary"} title={"Add Itenerary"}>
                    <AddItenary projectId={singleProject.id} customerId={singleProject.customerId} createdBy={singleProject.userId} />
                  </CreateDrawer>
                }
              </div>
            </div>
            <UserPrivateComponent permission={"readAll-itinerary"}>
              <TableComponent
                list={list?.itineraries}
                total={totali}
                loading={loading}
                columns={columns}
                csvFileName="Projects"
                containerClassName={'w-[100vw]'}
                paginatedThunk={fetchItinerary}
              />
            </UserPrivateComponent>
          </Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;
