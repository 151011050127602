import { Button, DatePicker, Form, Input, Modal, Select } from "antd"
import ActionBtn from "../Buttons/actionBtn";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Option } from "antd/es/mentions";
import { useDispatch, useSelector } from "react-redux";
import { updateItinerary } from "../../redux/rtk/features/itinerary/itinerarySlice";
import { StatusEnum } from "../../enums";
import { toast } from "react-toastify";
import { loadAllSale } from "../../redux/rtk/features/sale/saleSlice";

const ReturnRequest = ({ open, setOpen, details, refreshList }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const validateQuantity = (_, value) => {
        console.log(value, "VALUE", details)
        
        if (details?.sanctionedQuantity < value) {
            return Promise.reject(`Quantity Should be less than or equal to ${details.sanctionedQuantity}`);
        }
        if (value == 0) {
            return Promise.reject(`Quantity Should be 0`);
        }
        return Promise.resolve();
    };
    const formFields = useMemo(() => {
        
        const feilds = [
            {
                label: "Return Quantity",
                name: "quantity",
                required: true,
                message: "Please Enter Return Quantity!",
                placeholder: "Return Quantity",
                validation: validateQuantity
            },
        ];

        return feilds;
    }, [ details])

    


    const onFinish = async (values) => {
        try {
            console.log(values, "values..");
            
            const body = {
                quantity:parseInt(values.quantity),
                id: details.id,
                status: StatusEnum.RequestToReturn
            };
            console.log(body, 'body...')
            setLoading(true);
            const resp = await dispatch(updateItinerary(body));
            if (resp.payload.message === "success") {
                setLoading(false);
                onCloseModal(); 
                refreshList();
                toast.success('Itenary Updated Successfully')
            }
            else {
                setLoading(false)
                toast.error(resp.payload.message??'Something went wrong');
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.message??'Something went wrong');
            
        }
    };
    const onCloseModal = useCallback(()=>{
            form.resetFields()
            setOpen()
    },[])
    const onFinishFailed = (errorInfo) => {
    };

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"

        >
            <Modal
                title={<p>Return Request</p>}

                style={{
                    top: 20,
                }}
                footer={
                    <div className=" flex-row items-center justify-end w-[100%]">
                        <ActionBtn title={'Cancel'} onClick={onCloseModal} textClassName={`text-teal-700`} className={`bg-white text-teal-700 border border-teal-600  mx-4 `} />
                        <ActionBtn title={'Submit'}
                            onClick={() => { form.submit() }}
                            isLoading={loading} className={`bg-teal-700  border-teal-700`} />
                    </div>
                }
                // loading={loading}
                open={open}
                onCancel={setOpen}
            >
                <div className="items-center d-flex  p-2 ">
                    {formFields.map((item) => {
                        if (item.type === "select") {
                            return (
                                <Form.Item
                                    key={item.name}
                                    label={item.label}
                                    name={item.name}
                                    className="pt-2"
                                    rules={[{ required: item.required, message: item.message, validator: item.validation },
                                    { validator: item.validation }
                                    ]}
                                >
                                    <Select

                                        placeholder={item.placeholder}
                                        showSearch={item.showSearch}
                                        loading={item.loading}
                                        onChange={(value) => {
                                            item?.onChange(value);
                                            form.setFieldValue(item.name, value)
                                        }}
                                    >
                                        {item.options &&
                                            item.options.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.name}
                                                </Option>
                                            ))}
                                        {item.renderOption}
                                    </Select>
                                </Form.Item>
                            );
                        } else if (item.type === "date") {
                            return (
                                <Form.Item
                                    key={item.name}
                                    className="pt-2"
                                    label={item.label}
                                    name={item.name}
                                    rules={[{ required: item.required, message: item.message, }, { validator: item.validation }]}
                                >
                                    <DatePicker
                                        placeholder={item.placeholder}
                                        style={{ width: "100%", marginTop: 10 }}
                                    />
                                </Form.Item>
                            );
                        } else {
                            return (
                                <Form.Item
                                    key={item.name}
                                    label={item.label}
                                    name={item.name}
                                    className="pt-2"
                                    rules={[
                                        { required: item.required, message: item.message, },
                                        { validator: item.validation }
                                    ]}
                                >
                                    <Input
                                        // style={{ flex:1, display:'flex', backgroundColor:'yellow',width:'100%' }}
                                        className="w-[100%]"

                                        placeholder={item.placeholder}
                                        type={item.type ? item.type : "text"}
                                    />
                                </Form.Item>
                            );
                        }
                    })}
                </div>

            </Modal>
        </Form>
    )
}
export default ReturnRequest;