import React, { useRef } from "react";
import { Button, message } from "antd";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PrintToPdf = React.forwardRef(({ data, vatAmount }, ref) => {
  const totals = data.saleInvoiceProduct.reduce(
    (acc, item) => {
      const rate = item.productSalePrice * item.productQuantity;
      const tax = (rate * item.product.productVat) / 100;
      acc.totalRate += rate;
      acc.totalTax += tax;
      return acc;
    },
    { totalRate: 0, totalTax: 0 }
  );

  return (
    <div
      ref={ref}
      style={{
        fontFamily: "Arial, sans-serif",
        margin: "0",
        padding: "20px",
        fontSize: "14px",
        boxSizing: "border-box",
      }}
    >
      <style>
        {`
          @media print {
            @page {
              size: A4;
              margin: 20mm;
            }
          }
        `}
      </style>
      <header
        style={{
          textAlign: "center",
          fontSize: "12px",
          marginBottom: "10px",
        }}
      >
        <p>
          <strong>
            ORIGINAL FOR RECIPIENT/DUPLICATE FOR TRANSPORTER/TRIPLICATE FOR
            SUPPLIER
          </strong>
        </p>
        <p>
          <strong>{data?.company?.name}</strong>
        </p>
        <p>{data?.company?.address}</p>
        <p>{`Phone: ${data?.company?.phone}, Email: ${data?.company?.email}`}</p>
        <p>GST Registration No.: {data?.company?.gstIn}</p>
      </header>

      <h1
        style={{
          textAlign: "center",
          fontSize: "24px",
          marginBottom: "20px",
          backgroundColor: "#eee",
          padding: "10px",
        }}
      >
        Sales Invoice
      </h1>

      {/* Customer and Invoice Details */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div>
          <p>
            <strong>Customer Name:</strong> {data?.customer?.name}
          </p>
          <p>
            <strong>Email:</strong> {data?.customer?.email}
          </p>
          <p>
            <strong>Phone:</strong> {data?.customer?.phone}
          </p>
          <p>
            <strong>GST No.:</strong> {data?.customer?.gstin}
          </p>
          <p>
            <strong>Address:</strong> {data?.customer?.address}
          </p>
        </div>

        <div style={{ textAlign: "right" }}>
          <p>
            <strong>Invoice Number:</strong>{" "}
            {data?.singleSaleInvoice?.saleInvoiceProduct?.invoiceId || "-"}
          </p>
          <p>
            <strong>Date:</strong>{" "}
            {moment(data?.singleSaleInvoice?.date).format("DD/MM/YYYY")}
          </p>
          <p>
            <strong>Customer Ref:</strong>{" "}
            {data?.singleSaleInvoice?.ewayBill || "-"}
          </p>
        </div>
      </div>

      {/* Product Table */}
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
        }}
      >
        <thead>
          <tr>
            {[
              "Sr. No.",
              "Product",
              "Qty",
              "Description",
              "Tax",
              "Rate",
              "Taxable Amount",
            ].map((header) => (
              <th
                key={header}
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.saleInvoiceProduct.map((item, index) => {
            const { product, productQuantity, productSalePrice } = item;
            const tax =
              (productSalePrice * productQuantity * product.productVat) / 100;
            const rate = productSalePrice * productQuantity;
            const taxableAmount = rate + tax;

            return (
              <tr key={item.id}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {index + 1}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {product.name}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {productQuantity}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {product.description || "N/A"}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {tax.toFixed(2)}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {rate.toFixed(2)}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {taxableAmount.toFixed(2)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Totals Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p>
            <strong>Sub Total:</strong> {totals.totalRate.toFixed(2)}
          </p>
          <p>
            <strong>Total Tax:</strong> {totals.totalTax.toFixed(2)}
          </p>
          <p>
            <strong>Discount:</strong> {data.discount}
          </p>
          <p>
            <strong>Transport Charges:</strong> {data.transportCharge}
          </p>
          <p>
            <strong>Total Amount:</strong> {data.totalAmount}
          </p>
          <p>
            <strong>Paid Amount:</strong> {data.paidAmount}
          </p>
          <p>
            <strong>Pending Amount:</strong> {data.dueAmount}
          </p>
        </div>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <p>
            <strong>Customer Signature</strong>
          </p>
          <p>Printed On: {moment().format("DD/MM/YYYY HH:mm:ss")}</p>
        </div>
      </div>
    </div>
  );
});

const SaleInvoice = ({ data, vatAmount, id }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Invoice_${
      data?.singleSaleInvoice?.saleInvoiceProduct?.invoiceId || "Document"
    }`,
  });

  const handleSendInvoice = async () => {
    try {
      const totals = data.saleInvoiceProduct.reduce(
        (acc, item) => {
          const rate = item.productSalePrice * item.productQuantity;
          const tax = (rate * item.product.productVat) / 100;
          acc.totalRate += rate;
          acc.totalTax += tax;
          return acc;
        },
        { totalRate: 0, totalTax: 0 }
      );

      const docDefinition = {
        pageSize: "A4",
        pageMargins: [40, 60, 40, 60],
        content: [
          {
            text: "ORIGINAL FOR RECIPIENT/DUPLICATE FOR TRANSPORTER/TRIPLICATE FOR SUPPLIER",
            style: "headerLabel",
            alignment: "center",
            margin: [0, 0, 0, 20],
          },
          {
            text: data?.company?.name,
            style: "companyName",
            alignment: "center",
          },
          {
            text: data?.company?.address,
            style: "companyInfo",
            alignment: "center",
          },
          {
            text: `Phone: ${data?.company?.phone}, Email: ${data?.company?.email}`,
            style: "companyInfo",
            alignment: "center",
          },
          {
            text: `GST Registration No.: ${data?.company?.gstIn}`,
            style: "companyInfo",
            alignment: "center",
            margin: [0, 10, 0, 10],
          },

          {
            text: "Sales Invoice",
            style: "invoiceHeader",
            alignment: "center",
            margin: [0, 20, 0, 10],
          },

          {
            columns: [
              {
                width: "50%",
                text: [
                  { text: "Customer Name: ", bold: true },
                  `${data?.customer?.name}\n`,
                  { text: "Email: ", bold: true },
                  `${data?.customer?.email}\n`,
                  { text: "Phone: ", bold: true },
                  `${data?.customer?.phone}\n`,
                  { text: "GST No.: ", bold: true },
                  `${data?.customer?.gstin}\n`,
                  { text: "Address: ", bold: true },
                  `${data?.customer?.address}`,
                ],
              },
              {
                width: "50%",
                text: [
                  { text: "Invoice Number: ", bold: true },
                  `${
                    data?.singleSaleInvoice?.saleInvoiceProduct?.invoiceId ||
                    "-"
                  }\n`,
                  { text: "Date: ", bold: true },
                  `${moment(data?.singleSaleInvoice?.date).format(
                    "DD/MM/YYYY"
                  )}\n`,
                  { text: "Customer Ref: ", bold: true },
                  `${data?.singleSaleInvoice?.ewayBill || "-"}`,
                ],
                alignment: "right",
              },
            ],
            margin: [0, 0, 0, 20],
          },

          {
            table: {
              widths: ["auto", "*", "auto", "*", "auto", "auto", "auto"],
              body: [
                [
                  { text: "Sr. No.", style: "tableHeader" },
                  { text: "Product", style: "tableHeader" },
                  { text: "Qty", style: "tableHeader" },
                  { text: "Description", style: "tableHeader" },
                  { text: "Tax", style: "tableHeader" },
                  { text: "Rate", style: "tableHeader" },
                  { text: "Taxable Amount", style: "tableHeader" },
                ],
                ...data.saleInvoiceProduct.map((item, index) => [
                  index + 1,
                  item.product.name,
                  item.productQuantity,
                  item.product.description || "N/A",
                  (
                    (item.productSalePrice *
                      item.productQuantity *
                      item.product.productVat) /
                    100
                  ).toFixed(2),
                  (item.productSalePrice * item.productQuantity).toFixed(2),
                  (
                    item.productSalePrice * item.productQuantity +
                    (item.productSalePrice *
                      item.productQuantity *
                      item.product.productVat) /
                      100
                  ).toFixed(2),
                ]),
              ],
            },
            layout: "lightHorizontalLines",
          },

          {
            columns: [
              {
                width: "50%",
                text: [
                  { text: "Sub Total: ", bold: true },
                  `${totals.totalRate.toFixed(2)}\n`,
                  { text: "Discount: ", bold: true },
                  `${data.discount || "N/A"}\n`,
                  { text: "Transport Charges: ", bold: true },
                  `${data.transportCharge || "N/A"}\n`,
                  { text: "Total Tax: ", bold: true },
                  `${totals.totalTax.toFixed(2)}\n`,
                  { text: "Total Amount: ", bold: true },
                  `${data.totalAmount.toFixed(2)}\n`,
                  { text: "Paid Amount: ", bold: true },
                  `${data.paidAmount.toFixed(2)}\n`,
                  { text: "Pending Amount: ", bold: true },
                  `${data.dueAmount.toFixed(2)}\n`,
                ],
              },
              {
                width: "50%",
                text: [
                  {
                    text: `Printed On: ${moment().format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}`,
                    alignment: "right",
                    width: "auto",
                  },
                  {
                    text: "\n",
                    width: "auto",
                  },
                  {
                    text: "Customer Signature",
                    alignment: "center",
                    width: "auto",
                  },
                ],
                alignment: "right",
              },
            ],
            margin: [0, 20, 0, 10],
          },
        ],

        styles: {
          headerLabel: {
            fontSize: 10,
            bold: true,
          },
          companyName: {
            fontSize: 14,
            bold: true,
          },
          companyInfo: {
            fontSize: 10,
          },
          invoiceHeader: {
            fontSize: 24,
            bold: true,
            background: "#f2f2f2",
            padding: [10, 5],
          },
          tableHeader: {
            bold: true,
            fontSize: 12,
            backgroundColor: "#f2f2f2",
            padding: [8, 5],
          },
          totals: {
            fontSize: 12,
            bold: true,
            margin: [0, 10, 0, 10],
          },
        },
      };

      const pdfDoc = pdfMake.createPdf(docDefinition);

      pdfDoc.getBlob(async (blob) => {
        const formData = new FormData();
        formData.append("pdfFile", blob, "invoice.pdf");

        const response = await fetch(
          `http://82.112.227.185:5000/sale-invoice/invoice/send/${id}`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          message.success("Invoice sent successfully!");
        } else {
          message.error("Failed to send the invoice.");
        }
      });
    } catch (error) {
      message.error("An error occurred while sending the invoice.");
      console.error(error);
    }
  };

  return (
    <div>
      <div style={{ display: "none" }}>
        <PrintToPdf ref={componentRef} data={data} vatAmount={vatAmount} />
      </div>
      <Button type="primary" shape="round" onClick={handlePrint}>
        Print Invoice
      </Button>
      <Button
        type="primary"
        style={{ marginLeft: "10px" }}
        shape="round"
        onClick={handleSendInvoice}
      >
        Send Invoice
      </Button>
    </div>
  );
};

export default SaleInvoice;
