import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, message, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjects } from "../../redux/rtk/features/projects/projectsSlice";
import ViewBtn from "../Buttons/ViewBtn";
import CreateDrawer from "../CommonUi/CreateDrawer";
import TableComponent from "../CommonUi/ProjectTable";
import ProjectRepo from "../Invoice/ProjectRepo";
import axios from "axios";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import AddProject from "./addProject";

const GetAllProd = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.projects);
  const [searchValue, setSearchValue] = useState("");
  const [customerFilter, setCustomerFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [allProjects, setAllProjects] = useState("");
  const [allCustomers, setAllCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const rlist = list.allProjects || list;
  const totalPro = list.totalProjects;
  
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get("/customer?query=all");
        setAllCustomers(response?.data);
      } catch (error) {
        message.error("Failed to fetch customers");
        console.error(error);
      } finally {
        setLoadingCustomers(false);
      }
    };

    fetchCustomers();
  }, []);

  useEffect(() => {
    const fetchExport = async () => {
      try {
        const response = await axios.get("/project?query=all");
        setAllProjects(response?.data);
      } catch (error) {
        message.error("Failed to fetch projects");
        console.error(error);
      } 
    };

    fetchExport();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const queryType = searchValue ? "search" : "";
      await dispatch(
        fetchProjects({
          query: queryType,
          status: statusFilter,
          key: searchValue,
          page: 1,
          customer: customerFilter,
        })
      );
    };

    fetchData();
  }, [dispatch, searchValue, customerFilter, statusFilter]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      id: 2,
      title: "Name",
      dataIndex: "projectName",
      key: "projectName",
      render: (name, { id }) => (
        <Link to={`/admin/projectDetails/${id}`}>{name}</Link>
      ),
      width: "20%",
    },
    {
      id: 3,
      title: "Customer",
      dataIndex: ["customer", "name"],
      key: "customer.name",
      width: "15%",
    },
    {
      id: 4,
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: "10%",
    },
    {
      id: 5,
      title: "Est. Amount",
      dataIndex: "estimationAmount",
      key: "estimationAmount",
      width: "10%",
    },
    {
      id: 6,
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (status) => {
        switch (status) {
          case "NOT_STARTED":
            return "Not Started";
          case "IN_PROGRESS":
            return "In Progress";
          case "COMPLETED":
            return "Completed";
          default:
            return status;
        }
      },
    }, 
    {
      id: 7,
      title: "Created By",
      dataIndex: ["user", "username"],
      key: "user.username",
      width: "10%",
    }, 
    {
      id: 8,
      title: "Action",
      key: "action",
      render: ({ sku }, { id }) => (
        <div className="flex">
          <ViewBtn path={`/admin/projectDetails/${id}`} />
        </div>
      ),
      width: "10%",
    },
  ];

  return (
    <Card
      className="border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa]"
      bodyStyle={{ padding: 0, overflowX: "scroll" }}
    >
      <div className="lg:flex items-center justify-between pb-3">
        <h1 className="text-lg font-bold">Projects List</h1>
        <div className="flex gap-0 md:gap-5 items-center justify-between md:justify-start">
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              value={searchValue}
              onChange={handleSearch}
              className="border border-gray-300 rounded-md pl-10 pr-4 py-1 w-48"
            />
            <Select
              className="salelist-saleperson-input ml-2"
              loading={loadingCustomers}
              placeholder="Customer"
              style={{ width: 200 }}
              allowClear
              onChange={(value) => setCustomerFilter(value)}
            >
              <Select.Option value="">All</Select.Option>
              {allCustomers.map((customer) => (
                <Select.Option key={customer.id} value={customer.id}>
                  {customer.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              className="salelist-saleperson-input ml-2"
              placeholder="Status"
              style={{ width: 200 }}
              allowClear
              value={statusFilter}
              onChange={(value) => setStatusFilter(value)}
            >
              <Select.Option value="">All</Select.Option>
              <Select.Option value="NOT_STARTED">Not Started</Select.Option>
              <Select.Option value="IN_PROGRESS">In Progress</Select.Option>
              <Select.Option value="COMPLETED">Completed</Select.Option>
            </Select>
            <SearchOutlined className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
          </div>
          <ProjectRepo data={allProjects} />
          <CreateDrawer permission={"create-project"} title={"Add Project"}>
            <AddProject />
          </CreateDrawer>
        </div>
      </div>
      <UserPrivateComponent permission={"readAll-project"}>
        <TableComponent
          list={rlist}
          total={totalPro}
          loading={loading}
          columns={columns}
          csvFileName="Projects"
          paginatedThunk={fetchProjects}
        />
      </UserPrivateComponent>
    </Card>
  );
};

export default GetAllProd;
