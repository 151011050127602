import moment from "moment";
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import getSetting from "../../api/getSettings";
import { PrintAllBtn } from "../UI/CsvLinkBtn";
import * as XLSX from "xlsx";

import "./saleReport.module.css";

const ProdReportAll = ({ data, date, user, total }) => {
  console.log(data);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [settingData, setsettingData] = useState(null);
  useEffect(() => {
    getSetting().then((data) => setsettingData(data.result));
  }, []);
  const exportToExcel = () => {
    const ws = XLSX.utils.table_to_sheet(componentRef.current);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Product_Report");
    XLSX.writeFile(wb, "product.xlsx");
  };

  return (
    <div>
      {data && (
        <div className="hidden">
          <div ref={componentRef}>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>HSN</th>
                  <th>GST(%)</th>
                  <th>Min Sale Price</th>
                  <th>Purchase Price</th>
                  <th>Sale Price</th>
                  <th>QTY</th>
                  <th>Sub Category</th>
                  <th>Brand</th>
                  {/* <th>Due Amount</th> */}
                  {/* <th>Paid Amount</th> */}
                  {/* <th>Profit</th> */}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item) => {
                    const date = new Date(item.date);

                    const formattedDate = `${date.getDate()}/${
                      date.getMonth() + 1
                    }/${date.getFullYear()}`;
                    return (
                      <tr key={item.id}>
                        <td>{item?.id}</td>
                        <td>{item?.name}</td>
                        <td>{item?.sku}</td>
                        <td>{item?.productVat}</td>
                        {/* <td>{item?.user?.username}</td> */}
                        <td>{item?.productMinimumSalePrice}</td>
                        <td>{item?.productPurchasePrice}</td>
                        <td>{item?.productSalePrice}</td>
                        <td>{item?.productQuantity}</td>
                        <td>{item?.productSubCategory?.name}</td>
                        <td>{item?.productBrand?.name}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {settingData && (
        <PrintAllBtn className="w-full mx-0 md:py-2 text-center">
          <button onClick={exportToExcel}>Export All</button>
        </PrintAllBtn>
      )}
    </div>
  );
};

export default ProdReportAll;
