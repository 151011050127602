import {
  DeleteOutlined,
  EditOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Typography, Button, message } from "antd";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import TableComponent from "../CommonUi/ProjectTable";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import ViewBtn from "../Buttons/ViewBtn";
import {
  fetchSingleItinerary,
  deleteItinerary,
} from "../../redux/rtk/features/itinerary/itinerarySlice";
import Loader from "../loader/loader";
import { useState } from "react";
import { StatusEnum } from "../../enums";

const ItnearyDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleItinerary, loading } = useSelector((state) => state.itinerary);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchSingleItinerary(id));
      } finally {
      }
    };
    fetchData();
  }, [dispatch, id]);

  const handleDelete = async () => {
    const shouldDelete = window.confirm(
      "Are You Sure? Beacuse This Action Cannot Be Undone!"
    );

    if (shouldDelete) {
      try {
        await dispatch(deleteItinerary(id));
        message.success("Itinerary Deleted Successfully");
        navigate("/admin/projects");
      } catch (error) {
        message.error("Failed To Delete Itinerary");
      }
    }
  };

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="mr-top">
        {singleItinerary ? (
          <Fragment key={singleItinerary?.id}>
            <Card bordered={false} className="card-custom">
              <div className="card-header flex justify-between m-3">
                <div className="flex">
                  <SolutionOutlined className="text-xl" />
                  <span className="mr-left mt-1 font-bold text-lg">
                    ID : {singleItinerary?.id} | {singleItinerary?.projectName}
                  </span>
                </div>
                <div className="flex items-center gap-3">
                  {/* <Link
                    className="m-2 inline-block bg-blue-500 p-2 text-white rounded-md"
                    to={`/admin/projectDetails/itnearyDetails/${singleItinerary?.id}/update`}
                    state={{ data: {} }}
                  >
                    <EditOutlined
                      style={{
                        color: "white",
                        width: "unset !important",
                        padding: "0 8px 0 8px",
                      }}
                    />
                  </Link> */}
                  <Button
                    type="danger"
                    style={{ color: "white", width: "unset" }}
                    icon={<DeleteOutlined />}
                    onClick={handleDelete}
                  ></Button>
                </div>
              </div>
              <Row className="flex   ">
                <Col xs={24} xl={8} className="">
                  <div className="card-body  grid">
                    <h5 className="text-xl font-bold mb-2">
                      Itinerary Information
                    </h5>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Project Name :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleItinerary?.project?.projectName}
                      </span>
                    </p>
                    <p>
                      <Typography.Text strong className="font-medium text">
                        Customer Name :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleItinerary?.customer?.name}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Itinerary Name :
                      </Typography.Text>{" "}
                      <span className="text">{singleItinerary?.name}</span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Itinerary Remarks :
                      </Typography.Text>{" "}
                      <span className="text">{singleItinerary?.remarks}</span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Type :
                      </Typography.Text>{" "}
                      <span className="text">{singleItinerary?.type}</span>
                    </p>
                    {
                      (singleItinerary.status == StatusEnum.Returned) &&
                      <p>
                        <Typography.Text strong className="font-medium text">
                          Return Quantity :
                        </Typography.Text>{" "}
                        <span className="text">{singleItinerary?.quantity}</span>
                      </p>}
                    {
                      (singleItinerary.status == StatusEnum.Requested) &&
                      <p>
                        <Typography.Text strong className="font-medium text">
                          Quantity :
                        </Typography.Text>{" "}
                        <span className="text">{singleItinerary?.quantity}</span>
                      </p>
                    }


                    <p>
                      <Typography.Text strong className="font-medium text">
                        Sanctioned Quantity :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleItinerary?.sanctionedQuantity}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Cost Per Itinerary :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleItinerary?.costPerItinerary}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Total Amount :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleItinerary?.totalAmount}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Total Sanctioned Amount :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleItinerary?.totalSanctionedAmount}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Approved By :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleItinerary?.approvedByUser?.username}
                      </span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Status :
                      </Typography.Text>{" "}
                      <span className="text">{singleItinerary?.status}</span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Sale Id :
                      </Typography.Text>{" "}
                      <span className="text">{singleItinerary?.saleId}</span>
                    </p>
                    <p>
                      <Typography.Text strong className="font-medium text">
                        Product Id :
                      </Typography.Text>{" "}
                      <span className="text">{singleItinerary?.productId}</span>
                    </p>
                    <p>
                      <Typography.Text strong className="font-medium text">
                        Created By :
                      </Typography.Text>{" "}
                      <span className="text">
                        {singleItinerary?.createdByUser?.username}
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Card>
          </Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default ItnearyDetails;
