import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import queryGenerator from "../../../../utils/projectQuery";

const initialState = {
  list: [],
  error: "",
  singleItinerary: {},
  ilist: {},
  upItinerary: [],
  loading: false,
};

export const fetchItinerary = createAsyncThunk(
  "itinerary/fetch",
  async ({ project, ...params }) => {
    try {
      console.log({ project, params });
      const { data } = await axios.get(`itinerary/project/${project}`, { params });
      return data;
    } catch (error) {
      toast.error("Error in Fetching Itinerary, Please Try Again!");
      console.log(error.message);
      throw error;
    }
  }
);

export const fetchSingleItinerary = createAsyncThunk(
  "itinerary/single",
  async (id) => {
    try {
      const response = await axios.get(`itinerary/${id}`);
      const respData = response.data;
      toast.success("Successfully Fetched Itinerary Details");
      return respData;
    } catch (error) {
      toast.error("Error Fetching Itinerary, Please Try Again!");
      console.log(error.message);
      throw error;
    }
  }
);

export const addNewItinerary = createAsyncThunk(
  "itinerary/addnew",
  async (body) => {
    try {
      const resp = await axios.post(`itinerary`, body);
      toast.success("New Itinerary Created");
      return { ...resp.data, message: "success" };
    } catch (error) {
      console.log(error.message);
      return {
        status: false,
        error: error,
      };
    }
  }
);

export const updateItinerary = createAsyncThunk(
  "itinerary/update",
  async ({ id, ...body }, { rejectWithValue }) => {
    try {
      const resp = await axios.put(`itinerary/${id}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = resp;
      return {
        ...result,
        message: "success",
      };
    } catch (error) {
      console.log(error);

      if (axios.isAxiosError(error)) {
        if (error.response) {
          throw new Error(
            `Error: ${error.response.status} - ${error.response.data.message}`
          );
        }

        throw new Error("Error! Try Again");
      } else if (error.request) {
        throw new Error("Error! Try Again");
      } else {
        throw new Error("Error! Try Again");
      }
    }
  }
);

export const deleteItinerary = createAsyncThunk(
  "itinerary/delete",
  async (id) => {
    try {
      const resp = await axios.patch(`itinerary/${id}`);
      toast.success("Itinerary Deleted Successfully");
      return id;
    } catch (error) {
      toast.error("Error in Deleting Itinerary, Please Try Again!");
      console.log(error.message);
      throw error;
    }
  }
);

const ItinerarySlice = createSlice({
  name: "itinerary",
  initialState,
  ilist: {},
  reducers: {
    getItineraryById: (state, action) => {
      return {
        ...state,
        singleItinerary: state.list.find((item) => item.id == action.payload),
      };
    },
    clearSingleItinerary: (state, action) => {
      return {
        ...state,
        singleItinerary: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchItinerary.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchItinerary.fulfilled, (state, action) => {
      state.loading = false;
      // debugger;
      if(action.meta.arg.query!='all'){
        state.list = action.payload;
      }
    });

    builder.addCase(fetchItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });

    builder.addCase(fetchSingleItinerary.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchSingleItinerary.fulfilled, (state, action) => {
      state.loading = false;
      state.singleItinerary = action.payload;
    });

    builder.addCase(fetchSingleItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });

    builder.addCase(addNewItinerary.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addNewItinerary.fulfilled, (state, action) => {
      const { message, ...newItinerary } = action.payload;
      state.loading = false;

      if (!Array.isArray(state.list)) {
        state.list = [];
      }
      const list = [...state.list];
      list.push(newItinerary);
      state.list = list;
    });

    builder.addCase(addNewItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

    builder.addCase(updateItinerary.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateItinerary.fulfilled, (state, action) => {
      state.loading = false;

    });

    builder.addCase(updateItinerary.rejected, (state, action) => {
      state.loading = false;
      // state.error = action.payload.message;
    });

    builder.addCase(deleteItinerary.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteItinerary.fulfilled, (state, action) => {
      state.loading = false;
      state.list = state.list.filter(
        (itinerary) => itinerary.id !== action.payload
      );
    });

    builder.addCase(deleteItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default ItinerarySlice.reducer;
export const { getItineraryById, clearSingleItinerary } =
  ItinerarySlice.actions;
