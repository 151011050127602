const ActionBtn = ({ className, title, onClick, isLoading, textClassName }) => {
    return (
      <button
        onClick={onClick}
        className={`xs:px-3 px-1 text-sm md:text-base py-1 lg:px-5  border bg-teal-700 text-white rounded cursor-pointer ${className}`}
      >
        <div className='flex items-center gap-2'>
          <div className={`${textClassName} text-sm font-semibold`}>{(isLoading)?"Loading...":title}</div>
        </div>
      </button>
    )
  }
  export default ActionBtn;