import { useCallback, useState } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import "./styles/index.css";

import { Button, Card, message, Segmented, Select } from "antd";
import { useEffect } from "react";

import {
  SolutionOutlined,
  UserDeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { loadProduct } from "../../redux/rtk/features/product/productSlice";
import ViewBtn from "../Buttons/ViewBtn";
import CreateDrawer from "../CommonUi/CreateDrawer";
import TableComponent from "../CommonUi/TableComponent";
import ProdReportPrint from "../Invoice/ProdReport";
import ProdReportAll from "../Invoice/ProdReportAll";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import { StatusEnum } from "../../enums";
import { fetchAgentRequirements } from "../../api/agentRequirements";
import AddSale from "../sale/addSale";
import Sanction from "./components/sanction";
import ActionBtn from "../Buttons/actionBtn";
import { toast } from "react-toastify";
import { updateItinerary } from "../../redux/rtk/features/itinerary/itinerarySlice";
import axios from "axios";
// import AddProd from "./addProd";
// import GenerateBarcodePopUp from "./generateBarcodePopUp";

const AgentRequirements = (props) => {




  // Main Code



  const Nav = useNavigate();
  const dispatch = useDispatch();
  const [sanction, setSanction] = useState({
    modalIsOpen: false,
    itenaryDetails: undefined
  })
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const [loadingAgent, setLoadingAgent] = useState(true);
  const [allCustomers, setAllCustomers] = useState([]);
  const [allAgents, setAllAgents] = useState([]);
  const [requirements, setRequirements] = useState({
    list: [],
    search: '',
    page: 1,
    totalPage: 1,
    loading: false,
    filter: {
      status: [StatusEnum.Requested, StatusEnum.RequestToReturn],
      customer: '',
      type: '',
      agent:''
    }
  })

  const columns = [
    {
      id: 1,
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    {
      id: 2,
      title: "Iternay Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
    },
    {
      id: 3,
      title: "Project Name",
      dataIndex: ["project", "projectName"],
      key: "project.projectName",
      width: "15%",

    },
    {
      id: 4,
      title: "Customer",
      dataIndex: ["customer", "name"],
      key: "customer.name",
      width: "10%",

    },
    {
      id: 5,
      title: "Created By",
      dataIndex: ["createdByUser", "username"],
      key: "createdByUser.username",
      width: "10%",
    },
    {
      id: 6,
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: "15%",
    },
    {
      id: 6,
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
    },
    {
      id: 7,
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "8%",
    },
    {
      id: 8,
      title: "Amount",
      dataIndex: "costPerItinerary",
      key: "costPerItinerary",
      width: "8%",
    },
    {
      id: 9,
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
    },
    {
      id: 10,
      title: "Action",
      key: "action",

      render: (item) => (
        <div className="flex gap-3">

          {
            item.status == StatusEnum.Requested &&
            <>
              <ActionBtn title={'Sanction Iternary'}
                className={`bg-green-700`}
                onClick={() => {
                  toggleModal()
                  setSanction((state) => ({ ...state, itenaryDetails: item }))
                }} />
              <ActionBtn title={'Sale'} onClick={() => {
                Nav('/admin/sale')
              }} />
            </>
          }
          {
            item.status == StatusEnum.RequestToReturn &&
            <>
              <ActionBtn title={'Update Sanction'}
                className={`bg-green-700`}
                onClick={() => {
                  updateReturnRequest(item);
                }} />
              <ActionBtn title={'Update Sale'} onClick={() => {
                Nav(`/admin/sale/${item.saleId}`)
              }} />
            </>
          }
        </div>
      ),
      width: "20%",
    },
  ];

  const toggleModal = useCallback(() => {
    setSanction((state) => ({ ...state, modalIsOpen: !state.modalIsOpen }))
  }, [])

  const fetchCustomers = useCallback(async () => {
    try {
      const response = await axios.get("/customer?query=all");
      setAllCustomers(response?.data);
    } catch (error) {
      message.error("Failed to fetch customers");
      console.error(error);
    } finally {
      setLoadingCustomers(false);
    }
  }, [])
  const fetchAgents = useCallback(async () => {
    try {
      const response = await axios.get("/user/agent");
      const data = response?.data?.getAllUser??[];
      setAllAgents(data);
      console.log(data,'agents');
    } catch (error) {
      message.error("Failed to fetch customers");
      console.error(error);
    } finally {
      setLoadingAgent(false);
    }
  }, [])
  const updateReturnRequest = useCallback(async (itenary) => {
    try {
      const quantity = parseInt(itenary.sanctionedQuantity) - parseInt(itenary.quantity);
      const userId = await localStorage.getItem('id');
      const body = {
        id: itenary.id,
        sanctionedQuantity: quantity,
        totalAmount: itenary.costPerItinerary * quantity,
        totalSanctionedAmount: itenary.costPerItinerary * quantity,
        status: StatusEnum.Returned,
        approvedBy:parseInt(userId)
      };
      // console.log(body, 'body...')
      toggleLoading();
      const resp = await dispatch(updateItinerary(body));
      if (resp.payload.message === "success") {
        toggleLoading()
        getData(requirements);
        toast.success('Itenary Updated Successfully')
      }
      else {
        toggleLoading();
        toast.error(resp.payload.message ?? 'Something went wrong');
      }
    } catch (error) {
      toggleLoading()
      toast.error(error.message ?? 'Something went wrong');
    }
  }, [requirements])

  const toggleLoading = useCallback(() => {
    setRequirements((state) => {
      state.loading = !state.loading
      return state
    })
  }, [])
  const setFilter = useCallback((key, value) => {
    console.log(value, "VALUES...")
    setRequirements((state) => {
      state.filter = { ...state.filter, [key]: value }
      return { ...state }
    })
  }, [])


  const getData = useCallback(async (requirements) => {
    const { search, filter, page } = requirements;
    try {
      let params = {
        page,
        key: search,
        ...filter
      }
      if (search.trim().length > 0) {
        params['query'] = 'search';
      }
      toggleLoading()
      const res = await fetchAgentRequirements(params);
      if (res.status) {
        console.log(res, 'ress.')
        setRequirements((state) => {
          state.list = res.result.itineraries ?? res.result
          return {
            ...state,

          }
        })
      }
      toggleLoading();
    } catch (error) {
      toggleLoading();
      toast.error(error.message??"Something went wrong")
    }
  }, [])
  const loadMore = useCallback((page,count)=>{
      try{
        setRequirements((state)=>{
          return{
            ...state,
            page
          }
        })
      }catch(error){
        toast.error(error.message??"Something went wrong")
      }
  },[])

  useEffect(() => {
    console.log("INIT")
    getData(requirements);
  }, [requirements.page, requirements.filter,])


  useEffect(()=>{
    fetchCustomers()
    fetchAgents();
  },[])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      console.log("SERCH..")

      getData(requirements);

    }, 500); // Adjust delay as needed

    return () => clearTimeout(timeoutId);
  }, [requirements.search]);






  const handleSearch = useCallback((e) => {
    setRequirements((state) => {
      return {
        ...state,
        search: e.target.value
      }
    })
    // setSearchValue(e.target.value);
  }, []);

  return (
    <>
      <Sanction open={sanction.modalIsOpen} setOpen={toggleModal} details={sanction.itenaryDetails} refreshList={() => { getData(requirements) }} />
      <Card
        className="border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa] w-[100%]"
        bodyStyle={{ padding: 0, }}
      >
          <h1 className=" text-lg font-bold">Agent Requirements List</h1>
        <div className="lg:flex items-center justify-end  py-3">
          <div className="flex  gap-0  md:gap-5 items-center justify-between  md:justify-start">
            <Select
              className="salelist-saleperson-input ml-2"
              loading={loadingAgent}
              placeholder="Agent"
              style={{ width: 200 }}
              allowClear
              value={requirements.filter.agent}
              onChange={(value) => setFilter('agent',value)}
            >
              <Select.Option value="">All</Select.Option>
              {
              
              allAgents?.map((agent) => (
                <Select.Option key={agent.id} value={agent.id}>
                  {agent.username}
                </Select.Option>
              ))}
            </Select>
            <Select
              className="salelist-saleperson-input ml-2"
              loading={loadingCustomers}
              placeholder="Customer"
              style={{ width: 200 }}
              allowClear
              value={requirements.filter.customer}
              onChange={(value) => setFilter('customer',value)}
            >
              <Select.Option value="">All</Select.Option>
              {allCustomers.map((customer) => (
                <Select.Option key={customer.id} value={customer.id}>
                  {customer.name}
                </Select.Option>
              ))}
            </Select>

            <Select
              className="salelist-saleperson-input ml-2"
              placeholder="Type"
              style={{ width: 200 }}
              allowClear
              value={requirements.filter.type}
              onChange={(value) => setFilter('type', value)}
            >
              <Select.Option value="">All</Select.Option>
              <Select.Option value="MANPOWER">Manpower</Select.Option>
              <Select.Option value="PRODUCT">Product</Select.Option>
            </Select>

            <Select
              className="salelist-saleperson-input ml-2"
              placeholder="Status"
              style={{ width: 200 }}
              allowClear
              value={JSON.stringify(requirements.filter.status)}
              onChange={(value) => setFilter('status', JSON.parse(value))}
            >
              <Select.Option value={JSON.stringify(["REQUESTED", 'REQUEST_TO_RETURN'])}>All</Select.Option>
              <Select.Option value={JSON.stringify(["REQUESTED",])}>Requested</Select.Option>
              <Select.Option value={JSON.stringify(['REQUEST_TO_RETURN'])}>Retrurn Request</Select.Option>
            </Select>

            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                value={requirements.search}
                onChange={handleSearch}
                className="border border-gray-300 rounded-md pl-10 pr-4 py-1 w-48"
              />
              <SearchOutlined className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
            </div>


          </div>
        </div>

        <UserPrivateComponent permission={"readAll-getRequirement"}>

          <TableComponent
            list={requirements.list}
            total={requirements.totalPage}
            loading={requirements.loading}
            columns={columns}
            csvFileName="agent-requirement"
            onChangePage={loadMore}
            containerClassName={`w-[1600px]`}
          />
        </UserPrivateComponent>

      </Card>
    </>
  );
};




export default AgentRequirements;
