import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Typography,
  Select,
  DatePicker,
} from "antd";
import styles from "./AddWarehouse.module.css";
import { useDispatch, useSelector } from "react-redux";
import { addNewProject } from "../../redux/rtk/features/projects/projectsSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios for API requests
import { addNewItinerary } from "../../redux/rtk/features/itinerary/itinerarySlice";
import { loadProduct } from "../../redux/rtk/features/product/productSlice";

const AddItenary = ({ projectId, customerId, createdBy }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Title } = Typography;
  const { Option } = Select;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [isProduct, setIsProduct] = useState(false);
  const { list: productList, loading: productLoading } = useSelector(
    (state) => state.products
  );
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get("/customer");
        setCustomers(response.data);
      } catch (error) {
        console.log("Failed to fetch customers:", error.message);
      }
    };
    fetchCustomers();
    dispatch(loadProduct({ query: "all" }));
  }, []);

  

  const formFields = useMemo(() => {
    const feilds = [
      {
        label: "Itenary Type",
        name: "type",
        required: true,
        message: "Please Select a Itenary Type!",
        placeholder: "Select Itenary Type",
        type: "select",
        onChange: (value) => {
          setIsProduct(value == "PRODUCT");
        },
        options: [
          {
            name: "Manpower",
            value: 'MANPOWER'
          },
          {
            name: "Product",
            value: 'PRODUCT'
          },
        ]
      },
      {
        label: "Name",
        name: "name",
        required: true,
        message: "Please Enter Itenary Name!",
        placeholder: "Itenary Name",
      },
      
      // {
      //   label: "Cost Per Itenary",
      //   name: "costPerItinerary",
      //   required: true,
      //   message: "Please Enter Cost Per Itenary!",
      //   placeholder: "Enter Cost Per Itenary",
      // },
      {
        label: "Quantity",
        name: "quantity",
        required: true,
        message: "Please Enter Quantity!",
        placeholder: "Enter Quantity",
      },
      {
        label: "Remarks",
        name: "remarks",
        required: false,
        message: "Please Enter remarks!",
        placeholder: "Enter Remarks",
      },

    ];
    const productFeild = {
      label: "Product",
      name: "product",
      required: true,
      showSearch: true,
      loading: productLoading,
      message: "Please Select Product",
      placeholder: "Select Itenary Product",
      type: "select",
      onChange:(value)=>{
          const data = JSON.parse(value);
          console.log(data, "DATA..")
          form.setFieldValue('costPerItinerary',data.productSalePrice)
          form.setFieldValue('name',data.name);
          form.setFieldValue('productId',data.id)
      },
      options: productList?.map((item)=>({name:item?.name, value:JSON.stringify(item)}))
      
    };
    if (isProduct) {
      feilds.splice(1, 0, productFeild)
    }
    return feilds;
  }, [isProduct, productLoading, productList])




  

  const onFinish = async (values) => {
    try {
      setLoading(true);
      console.log(values, "values..");
      let body = {
        ...values,
        costPerItinerary:0,
        remarks:values.remarks??'',
        totalAmount:0,
        quantity:parseInt(values.quantity),
        projectId: projectId,
        createdBy: createdBy,
        customerId: customerId,
        status: "REQUESTED"
      };
      if(values.type == 'PRODUCT'){
        const product = JSON.parse(values?.product);
        body['productId']= product.id??"";
        body['costPerItinerary'] = product.productSalePrice;
        body['totalAmount'] = product.productSalePrice * body.quantity;
      }
      console.log(body, 'body...')
      const resp = await dispatch(addNewItinerary(body));

      if (resp.payload.message === "success") {
        setLoading(false);
        form.resetFields();
        
      }
      else{
        setLoading(false)
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    console.log("Failed:", errorInfo);
  };

  const onClick = () => {
    setLoading(true);
  };

  return (
    <>
      <Card bordered={false} className="criclebox h-full">
        <Title level={4} className="m-3 text-center">
          Add New Itenary
        </Title>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"

        >
          {formFields.map((item) => {
            if (item.type === "select") {
              return (
                <Form.Item
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  rules={[{ required: item.required, message: item.message }]}
                >
                  <Select
                    style={{ marginTop: 10 }}
                    placeholder={item.placeholder}
                    showSearch={item.showSearch}
                    loading={item.loading}
                    onChange={(value) => {
                      item?.onChange(value);
                      form.setFieldValue(item.name, value)
                    }}
                  >
                    {item.options &&
                      item.options.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.name}
                        </Option>
                      ))}
                    {item.renderOption}
                  </Select>
                </Form.Item>
              );
            } else if (item.type === "date") {
              return (
                <Form.Item
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  
                  rules={[{ required: item.required, message: item.message }]}
                >
                  <DatePicker
                    placeholder={item.placeholder}
                    style={{ width: "100%", marginTop: 10 }}
                  />
                </Form.Item>
              );
            } else {
              return (
                <Form.Item
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  rules={[
                    { required: item.required, message: item.message },
                  ]}
                >
                  <Input
                    style={{ marginTop: 10 }}
                    placeholder={item.placeholder}
                    type={item.type ? item.type : "text"}
                  />
                </Form.Item>
              );
            }
          })}


          <Form.Item className={styles.addProdBrandBtnContainer}>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              loading={loading}
              onClick={onClick}
              className="w-100"
            >
              Add Itenary
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default AddItenary;
