import { Button, Form, Input, Select, DatePicker, message } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./projectDetails.css";
import moment from "moment";
import {
  updateProject,
  fetchSingleProject,
} from "../../redux/rtk/features/projects/projectsSlice";
import axios from "axios";

const UpdateWarehouseDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { singleProject } = useSelector((state) => state.projects);
  const [customers, setCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(true);

  useEffect(() => {
    dispatch(fetchSingleProject(id));
  }, [dispatch, id]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get("/customer");
        setCustomers(response?.data);
      } catch (error) {
        message.error("Failed to fetch customers");
        console.error(error);
      } finally {
        setLoadingCustomers(false);
      }
    };

    fetchCustomers();
  }, []);

  const onFinish = async (values) => {
    try {
      const payload = {
        ...values,
        estimationAmount: parseInt(values.estimationAmount, 10),
        duration: parseInt(values.duration, 10),
      };
      await dispatch(updateProject({ id, ...payload }));
      message.success("Project Updated Successfully");
      navigate(`/admin/projectDetails/${id}`);
    } catch (error) {
      message.error("Failed To Update Project");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const formatDate = (date) => {
    return date ? moment(date) : null;
  };

  return (
    <Fragment>
      <div style={{ alignContent: "center", textAlign: "-webkit-center" }}>
        <Form
          style={{
            maxWidth: "700px",
            border: "1px solid #ccc",
            padding: "40px",
            borderRadius: "10px",
          }}
          name="updateWarehouseForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            ...singleProject,
            startDate: formatDate(singleProject.startDate),
            endDate: formatDate(singleProject.endDate),
          }}
        >
          <h1 className="font-bold text-2xl mb-5">Update Project</h1>
          <Form.Item
            label="Name"
            name="projectName"
            rules={[
              { required: true, message: "Please input the project name!" },
            ]}
          >
            <Input className="mb-2" />
          </Form.Item>

          <Form.Item
            label="Customer"
            name="customerId"
            
            rules={[{ required: true, message: "Please select a customer!"}]}
          >
            <Select
              style={{ textAlign: "left" }}
              className="mb-2"
              disabled={singleProject.status == "IN_PROGRESS"}
              placeholder="Select Customer"
              loading={loadingCustomers}
            >
              {customers.getAllCustomer &&
                customers.getAllCustomer.map((customer) => (
                  <Select.Option
                    style={{ textAlign: "left" }}
                    key={customer.id}
                    value={customer.id}
                  >
                    {customer.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "Please select a status!" }]}
          >
            <Select
              style={{ textAlign: "left" }}
              className="mb-2"
              disabled
              placeholder="Select Status"
            >
              <Select.Option style={{ textAlign: "left" }} value="NOT_STARTED">
                Not Started
              </Select.Option>
              <Select.Option style={{ textAlign: "left" }} value="IN_PROGRESS">
                In Progress
              </Select.Option>
              <Select.Option style={{ textAlign: "left" }} value="COMPLETED">
                Completed
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <Input className="mb-2" />
          </Form.Item>

          <Form.Item
            label="Duration"
            name="duration"
            rules={[{ required: true, message: "Please input the duration!" }]}
          >
            <Input type="number" className="mb-2" />
          </Form.Item>

          <Form.Item
            label="Est. Amount"
            name="estimationAmount"
            rules={[
              {
                required: true,
                message: "Please input the estimation amount!",
              },
            ]}
          >
            <Input type="number" className="mb-2" />
          </Form.Item>

          {/* <Form.Item
            label="Start Date"
            name="startDate"
            rules={[
              { required: true, message: "Please input the start date!" },
            ]}
          >
            <DatePicker className="mb-2" />
          </Form.Item> */}

          {/* <Form.Item
            label="End Date"
            name="endDate"
            rules={[{ required: true, message: "Please input the end date!" }]}
          >
            <DatePicker className="mb-2" />
          </Form.Item> */}

          <Form.Item>
            <Button className="mt-5" type="primary" htmlType="submit">
              Update Project
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Fragment>
  );
};

export default UpdateWarehouseDetails;
