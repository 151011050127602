import moment from "moment";
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import getSetting from "../../api/getSettings";
import { PrintAllBtn } from "../UI/CsvLinkBtn";
import * as XLSX from "xlsx";

import "./saleReport.module.css";

const IternaryRepo = ({ data, date, user, total, fetchData }) => {
  console.log(data);
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [settingData, setsettingData] = useState(null);
  useEffect(() => {
    getSetting().then((data) => setsettingData(data));
  }, []);
  const exportToExcel = async() => {
    
    setLoading(true);
    await fetchData();
    setTimeout(()=>{
      const ws = XLSX.utils.table_to_sheet(componentRef.current);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Iterary");
      XLSX.writeFile(wb, "iterary.xlsx");
      setLoading(false);
    },10000)
    
  };

  return (
    <div>
      {data && (
        <div className="hidden">
          <div ref={componentRef}>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Created By</th>
                  <th>Type</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                  <th>Last Updated</th>
                  <th>Approved By</th>
                  <th>Status</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data?.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item?.id}</td>
                        <td>{item?.name}</td>
                        <td>{item?.createdByUser?.username}</td>
                        <td>{item?.type}</td>
                        <td>{item?.quantity}</td>
                        <td>{item?.totalAmount}</td>
                        <td>{new Intl.DateTimeFormat('en-GB').format(new Date(item?.updatedAt))}</td>
                        <td>{item?.approvedByUser?.username}</td>
                        <td>{item?.status}</td>
                        <td>{item?.remarks}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {settingData && (
        <PrintAllBtn
          className="w-full mx-0 md:py-1 text-center"
        >
          <button style={{ padding: "2px 20px", fontWeight: "500" }} onClick={exportToExcel}>{loading?`Loading...`:`Export All`}</button>
        </PrintAllBtn>
      )}
    </div>
  );
};

export default IternaryRepo;
