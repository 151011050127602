import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Typography,
  Select,
  DatePicker,
} from "antd";
import styles from "./AddWarehouse.module.css";
import { useDispatch } from "react-redux";
import { addNewProject } from "../../redux/rtk/features/projects/projectsSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios for API requests

const AddWarehouse = ({ drawer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Title } = Typography;
  const { Option } = Select;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get("/customer");
        setCustomers(response.data);
      } catch (error) {
        console.log("Failed to fetch customers:", error.message);
      }
    };
    fetchCustomers();
  }, []);

  const formFields = [
    {
      label: "Project Name",
      name: "projectName",
      required: true,
      message: "Please Enter Project Name!",
      placeholder: "Project Name",
    },
    {
      label: "Customer",
      name: "customerId",
      required: true,
      message: "Please Select a Customer!",
      placeholder: "Select Customer",
      type: "select",
    },
    {
      label: "Description",
      name: "description",
      required: true,
      message: "Please Input Project Description!",
      placeholder: "Project Description",
    },
    // {
    //   label: "Start Date",
    //   name: "startDate",
    //   required: true,
    //   message: "Please Input Start Date!",
    //   placeholder: "Start Date",
    //   type: "date",
    // },
    // {
    //   label: "End Date",
    //   name: "endDate",
    //   required: true,
    //   message: "Please Input End Date!",
    //   placeholder: "End Date",
    //   type: "date",
    // },
    {
      label: "Duration",
      name: "duration",
      required: true,
      message: "Please Input Project Duration!",
      placeholder: "Project Duration in Days",
    },
    {
      label: "Est. Amount",
      name: "estimationAmount",
      required: true,
      message: "Please Input Estimation Amount!",
      placeholder: "Estimation Amount",
    },
  ];

  const validateName = (_, value) => {
    if (!/^[a-zA-Z ]+$/.test(value)) {
      return Promise.reject("Invalid Project Name!");
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    try {
      console.log(values, "values..");
      const body = {
        ...values,
        userId: parseInt(localStorage.getItem("id"), 10),
        status: "NOT_STARTED",
        actualAmount: null,
      };
      const resp = await dispatch(addNewProject(body));

      if (resp.payload.message === "success") {
        setLoading(false);
        form.resetFields();
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    console.log("Failed:", errorInfo);
  };

  const onClick = () => {
    setLoading(true);
  };

  return (
    <>
      <Card bordered={false} className="criclebox h-full">
        <Title level={4} className="m-3 text-center">
          Add New Project
        </Title>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {formFields.map((item) => {
            if (item.type === "select") {
              return (
                <Form.Item
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  rules={[{ required: item.required, message: item.message }]}
                >
                  <Select
                    style={{ marginTop: 10 }}
                    placeholder={item.placeholder}
                  >
                    {customers.getAllCustomer &&
                      customers.getAllCustomer.map((customer) => (
                        <Option key={customer.id} value={customer.id}>
                          {customer.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              );
            } else if (item.type === "date") {
              return (
                <Form.Item
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  rules={[{ required: item.required, message: item.message }]}
                >
                  <DatePicker
                    placeholder={item.placeholder}
                    style={{ width: "100%", marginTop: 10 }}
                  />
                </Form.Item>
              );
            } else {
              return (
                <Form.Item
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  rules={[
                    { required: item.required, message: item.message },
                    {
                      validator:
                        item.name === "name" ? validateName : undefined,
                    },
                  ]}
                >
                  <Input
                    style={{ marginTop: 10 }}
                    placeholder={item.placeholder}
                    type={item.type ? item.type : "text"}
                  />
                </Form.Item>
              );
            }
          })}

          <Form.Item className={styles.addProdBrandBtnContainer}>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              loading={loading}
              onClick={onClick}
              className="w-100"
            >
              Add Project
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default AddWarehouse;
