import {
  DeleteOutlined,
  EditOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Typography, Button, message } from "antd";
import { Fragment, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import WReportPrint from "../Invoice/WReportPrint";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import TableComponent from "../CommonUi/TableComponent";
import {
  getWarehouseById,
  wproduct,
  fetchWarehouses,
  clearSingleWarehouse,
  deleteWarehouse,
} from "../../redux/rtk/features/warehouse/warehouseSlice";
import Loader from "../loader/loader";
import { useState } from "react";

const ProjectDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const warehouse = useSelector((state) => state.warehouse.singleWarehouse);
  // const plist = useSelector((state) => state.plist);
  const [wproductResponse, setWproductResponse] = useState({});
  const [productLoading, setProductLoading] = useState(true);

  console.log(wproductResponse);
  // console.log(id);

  const columns = [
    {
      title: "S No.",
      dataIndex: "productId",
      key: "productId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Quantity",
      dataIndex: "productQuantity",
      key: "productQuantity",
    },
  ];

  useEffect(() => {
    (async () => {
      if (!warehouse.name) {
        await dispatch(getWarehouseById(id));
        // await dispatch(wproduct({ id }));
      }
    })();

    return () => {
      dispatch(clearSingleWarehouse());
    };
  }, [dispatch, id]);

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          const response = await dispatch(wproduct({ id, query: searchValue }));
          setWproductResponse(response?.payload?.inventoryProduct);
          setProductLoading(false);
        } catch (error) {
          console.error("Error fetching wproduct:", error);
        }
      }
    })();
  }, [dispatch, searchValue, id]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleDelete = async () => {
    const shouldDelete = window.confirm(
      "Are You Sure? Beacuse This Action Cannot Be Undone!"
    );

    if (shouldDelete) {
      try {
        await dispatch(deleteWarehouse(id));
        message.success("Warehouse Deleted Successfully");
        navigate("/admin/warehouse");
      } catch (error) {
        message.error("Failed To Delete Warehouse");
        console.log(error.message);
      }
    }
  };

  const isLogged = Boolean(localStorage.getItem("isLogged"));

  if (!isLogged) {
    return <Navigate to={"/admin/auth/login"} replace={true} />;
  }

  return (
    <div>
      <div className="mr-top">
        {warehouse ? (
          <Fragment key={warehouse?.id}>
            <Card bordered={false} className="card-custom">
              <div className="card-header flex justify-between m-3">
                <div className="flex">
                  <SolutionOutlined className="text-xl" />
                  <span className="mr-left mt-1 font-bold text-lg">
                    ID : {warehouse?.id} | {warehouse?.name}
                  </span>
                </div>
                <div className="flex items-center gap-3">
                  <Link
                    className="m-2 inline-block bg-blue-500 p-2 text-white rounded-md"
                    to={`/admin/warehouseDetails/${warehouse?.id}/update`}
                    state={{ data: {} }}
                  >
                    <EditOutlined
                      style={{
                        color: "white",
                        width: "unset !important",
                        padding: "0 8px 0 8px",
                      }}
                    />
                  </Link>
                  <Button
                    type="danger"
                    style={{ color: "white", width: "unset" }}
                    icon={<DeleteOutlined />}
                    onClick={handleDelete}
                  ></Button>
                </div>
              </div>
              <Row className="flex   ">
                <Col xs={24} xl={8} className="">
                  <div className="card-body  grid">
                    <h5 className="text-xl font-bold mb-2">
                      Warehouse Information
                    </h5>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Warehouse Name :
                      </Typography.Text>{" "}
                      <span className="text">{warehouse?.name}</span>
                    </p>

                    <p>
                      <Typography.Text strong className="font-medium text">
                        Warehouse Location :
                      </Typography.Text>{" "}
                      <span className="text">{warehouse?.location}</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Card>
            <Card
              className="border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa]"
              bodyStyle={{ padding: 0 }}
            >
              <div className="md:flex items-center justify-between pb-3">
                <h1 className="text-lg font-bold">Product List</h1>
                <div className="flex justify-between md:justify-start gap-1 md:gap-3 items-center">
                  <WReportPrint
                    data={wproductResponse}
                    // date={{ startdate, enddate }}
                    // user={user}
                    // total={total?._sum}
                  />
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchValue}
                      onChange={handleSearch}
                      className="border border-gray-300 rounded-md pl-10 pr-4 py-1 w-48"
                    />
                    <SearchOutlined className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
                  </div>

                  <div className="xxs:w-1/2 md:w-full xxs:flex-col md:flex-row  flex xxs:gap-1"></div>
                </div>
              </div>
              <UserPrivateComponent permission={"readAll-saleInvoice"}>
                <TableComponent
                  list={wproductResponse}
                  columns={columns}
                  loading={productLoading}
                  // total={total?._count?.id}
                />
              </UserPrivateComponent>
            </Card>
          </Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;
