import axios from "axios";

export const fetchAgentRequirements = async (params) => {
    try {
        const res = await axios.get(`itinerary`, {params})
        return {
            result: res.data,
            message: "success",
            status: true,
        };
        // return data;
    } catch (error) {
        return {
            status: false,
            message: error?.message ?? "Something went wrong"
        }
    }
}
