import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import queryGenerator from "../../../../utils/projectQuery";

const initialState = {
  list: [],
  error: "",
  singleProject: {},
  plist: {},
  upProject: [],
  loading: false,
};

export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async (arg) => {
    try {
      const query = queryGenerator(arg);
      const { data } = await axios.get(`project?${query}`);
      // toast.success("Successfully Fetch Projects");
      return data;
    } catch (error) {
      toast.error("Error in Fetching Projects, Please Try Again!");
      console.log(error.message);
      throw error;
    }
  }
);

export const fetchSingleProject = createAsyncThunk(
  "projects/fetchSingleProject",
  async (id) => {
    try {
      const response = await axios.get(`project/${id}`);
      const respData = response.data;
      toast.success("Successfully Fetched Project Details");
      return respData;
    } catch (error) {
      toast.error("Error fetching project, please try again!");
      console.log(error.message);
      throw error;
    }
  }
);

export const addNewProject = createAsyncThunk(
  "project/addNewProject",
  async (body) => {
    try {
      const resp = await axios.post(`project`, body);
      toast.success("New Project Created");
      return { ...resp.data, message: "success" };
    } catch (error) {
      console.log(error.message);
      return {
        status: false,
        error: error,
      };
    }
  }
);

export const updateProject = createAsyncThunk(
  "updateProject",
  async ({ id, ...body }, { rejectWithValue }) => {
    try {
      const resp = await axios.put(`project/${id}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = resp;
      return result;
    } catch (error) {
      console.log(error);
      
      if (axios.isAxiosError(error)) {
        if (error.response) {
          throw new Error(
            `Error: ${error.response.status} - ${error.response.data.message}`
          );
        }

        throw new Error("Error! Try Again");
      } else if (error.request) {
        throw new Error("Error! Try Again");
      } else {
        throw new Error("Error! Try Again");
      }
    }
  }
);

export const deleteProject = createAsyncThunk("deleteProject", async (id) => {
  try {
    const resp = await axios.patch(`project/${id}`);
    toast.success("Project Deleted Successfully");
    return id;
  } catch (error) {
    toast.error("Error in Deleting Project, Please Try Again!");
    console.log(error.message);
    throw error;
  }
});

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  plist: {},
  reducers: {
    getProjectById: (state, action) => {
      return {
        ...state,
        singleProject: state.list.find((item) => item.id == action.payload),
      };
    },
    clearSingleproject: (state, action) => {
      return {
        ...state,
        singleProject: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload;
    });

    builder.addCase(fetchProjects.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });

    builder.addCase(fetchSingleProject.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchSingleProject.fulfilled, (state, action) => {
      state.loading = false;
      state.singleProject = action.payload.singleProject;
    });

    builder.addCase(fetchSingleProject.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    });

    builder.addCase(addNewProject.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addNewProject.fulfilled, (state, action) => {
      const { message, ...newProject } = action.payload;
      state.loading = false;

      if (!Array.isArray(state.list)) {
        state.list = [];
      }
      const list = [...state.list];
      list.push(newProject);
      state.list = list;
    });

    builder.addCase(addNewProject.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

    builder.addCase(updateProject.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.loading = false;
      state.upProject = action.payload
      console.log(state.upProject);
      
      // const index = state.list.findIndex(
      //   (project) => project.id === action.payload.id
      // );
      // if (index !== -1) {
      //   state.list[index] = action.payload;
      // }
    });

    builder.addCase(updateProject.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    builder.addCase(deleteProject.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.loading = false;
      state.list = state.list.filter(
        (project) => project.id !== action.payload
      );
    });

    builder.addCase(deleteProject.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default projectsSlice.reducer;
export const { getProjectById, clearSingleproject } = projectsSlice.actions;
