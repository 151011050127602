import moment from "moment";
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import getSetting from "../../api/getSettings";
import { PrintAllBtn } from "../UI/CsvLinkBtn";
import * as XLSX from "xlsx";

import "./saleReport.module.css";

const ProjectRepo = ({ data, date, user, total }) => {
  console.log(data);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [settingData, setsettingData] = useState(null);
  useEffect(() => {
    getSetting().then((data) => setsettingData(data.result));
  }, []);
  const exportToExcel = () => {
    const ws = XLSX.utils.table_to_sheet(componentRef.current);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Projects");
    XLSX.writeFile(wb, "projects.xlsx");
  };

  return (
    <div>
      {data && (
        <div className="hidden">
          <div ref={componentRef}>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Customer</th>
                  <th>Duration</th>
                  <th>Est. Amount</th>
                  <th>Status</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  {/* <th>Due Amount</th> */}
                  {/* <th>Paid Amount</th> */}
                  {/* <th>Profit</th> */}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item) => {
                    const formatDate = (dateString) => {
                      const date = new Date(dateString);
                      return `${date.getDate()}/${
                        date.getMonth() + 1
                      }/${date.getFullYear()}`;
                    };

                    return (
                      <tr key={item.id}>
                        <td>{item?.id}</td>
                        <td>{item?.projectName}</td>
                        <td>{item?.description}</td>
                        <td>{item?.customer?.name}</td>
                        <td>{item?.duration}</td>
                        <td>{item?.estimationAmount}</td>
                        <td>{item?.status}</td>
                        <td>{formatDate(item?.startDate)}</td>
                        <td>{formatDate(item?.endDate)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {settingData && (
        <PrintAllBtn
          className="w-full mx-0 md:py-1 text-center"
        >
          <button style={{ padding: "2px 20px", fontWeight: "500" }} onClick={exportToExcel}>Export All</button>
        </PrintAllBtn>
      )}
    </div>
  );
};

export default ProjectRepo;
